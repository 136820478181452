import React from "react";

import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

const meta = {
  title: "Mykonos by Gryparis | Restaurant & Cocktail bar in Mykonos ",
  description:
    "The legendary dining & nightlife destination under Mykonos Windmills. Feast on fresh fish & seafood and divine cocktails facing the sunset & Little Venice.",
  url: "https://www.http://mykonosbygryparis.com/",
  og: "/OG.jpg",
};

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      {/* Primary Meta Tags  */}
      <title>{meta.title}</title>
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />
      {/* Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={meta.url} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.og} />
      {/* Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={meta.url} />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={meta.og} />

      <script
        src={withPrefix("simpleParallax.min.js")}
        type="text/javascript"
      />
      <script src={withPrefix("script.js")} type="text/javascript" />
    </Helmet>
    {children}
  </>
);

export default Layout;
