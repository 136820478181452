import React from "react";

import * as styles from "./Header.module.scss";

import Link from "../../components/Link";

import logoSVG from "../../assets/images/icons/logo_dark_blue.svg";

const contactLink = {
  href: "#contact",
  target: "",
};

const logo = {
  src: logoSVG,
  alt: "",
  style: { objectFit: "contain" },
};

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.middle}>
          <img
            className={styles.logo}
            {...logo}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        </div>
        <div className={styles.right}>
          <Link className={styles.contactLink} {...contactLink}>
            CONTACT
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
