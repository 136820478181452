import React from "react";

import Link from "../../components/Link";

import * as styles from "./Footer.module.scss";

import restaurantSVG from "../../assets/images/icons/rest_logo_white.svg";
import barSVG from "../../assets/images/icons/bar_logo_white.svg";
import logoSVG from "../../assets/images/icons/logo.svg";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import instagramSVG from "../../assets/images/icons/instagram_white.svg";
import facebookSVG from "../../assets/images/icons/facebook_white.svg";

const restaurantImage = {
  src: restaurantSVG,
  alt: "",
};
const barImage = {
  src: barSVG,
  alt: "",
};
const logoImage = {
  src: logoSVG,
  alt: "",
};

const instagram = {
  link: {
    href: "https://instagram.com/mykonos_bygryparis",
  },
  img: {
    src: instagramSVG,
    alt: "",
  },
};
const facebook = {
  link: {
    href: "https://www.facebook.com/Mykonos-by-Gryparis-100533645595805",
  },
  img: {
    src: facebookSVG,
    alt: "",
  },
};

const Footer = () => (
  <footer className={styles.block} id="contact">
    <div className={styles.container}>
      {/* CONTENT */}
      <div className={styles.content}>
        <First />
        <Second />
        <Third />
        <Fourth />

        <div className={styles.social}>
          <SocialMedia {...instagram} />
          <SocialMedia {...facebook} />
        </div>

        <div className={styles.left}>2021 © MYKONOS BY GRYPARIS</div>
        <div className={styles.right}>
          Design & Development by{" "}
          <Link href="https://www.f-design.gr/">F-Design</Link>
        </div>
      </div>

      {/* FOOTER */}
      <div className={styles.footer}></div>
    </div>
  </footer>
);

export default Footer;

/* FIRST */
const First = () => (
  <div className={styles.first}>
    {/* LOGO */}
    <img className={styles.logo} {...restaurantImage} />
    {/* TEXT */}
    <div className={styles.text}>
      Kato Myloi,
      <br />
      Mykonos 84600
      <br />
      <br />
      <Link href="tel:0030 2289024676">+30 22890 24676</Link>
      <br />
      <Link href="mailto:info@mykonosbygryparis.com">
        info@mykonosbygryparis.com
      </Link>
    </div>
  </div>
);

/* SECOND */
const Second = () => (
  <div className={styles.second}>
    {/* LOGO */}
    <img className={styles.logo} {...barImage} />

    {/* TEXT */}
    <div className={styles.text}>
      Kato Myloi,
      <br />
      Mykonos 84600
      <br />
      <br />
      <Link href="tel:0030 2289024676">+30 22890 24676</Link>
      <br />
      <Link href="mailto:info@mykonosbygryparis.com">
        info@mykonosbygryparis.com
      </Link>
    </div>
  </div>
);

/* THIRD */
const Third = () => {
  return <div className={styles.third}></div>;
};

/* FOURTH */
const Fourth = () => {
  return (
    <div className={styles.fourth}>
      {/* LOGO */}
      <img className={styles.logo} {...logoImage} />
    </div>
  );
};
