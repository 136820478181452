import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";

import * as styles from "./RestaurantMenu.module.scss";

const menu = {
  Header: (props) => (
    <div {...props} className={`${styles.title} ${props.className || ""}`}>
      Our Flavours
    </div>
  ),
  text: "Our reputation was forged through our daily commitment to offering you fresher-than-fresh local products. Selected farm-and-sea-to-table products are sublimated, prepared in the most flavourful and authentic traditional recipes. Seafood and fish specialists for more than 30 years, we strive to provide you with a unique culinary souvenir from Mykonos. Discover our legendary tastes!",
  Footer: (props) => (
    <ul {...props} className={`${styles.links} ${props.className || ""}`}>
      {/* <li>
        <a href="">LUNCH MENU</a>
      </li>
      <li>
        <a href="">DINNER MENU</a>
      </li>
      <li>
        <a href="">WINE LIST</a>
      </li> */}
      <li>
        <b>MENU SOON AVAILABLE</b>
      </li>
    </ul>
  ),
};

const image = {
  src: "../../../assets/images/restaurant/menu/dish.png",
  alt: "",
  objectFit: "contain",
};

const RestaurantMenu = () => (
  <div className={styles.menu}>
    {/* LEFT */}
    <div className={styles.left}>
      <div className={styles.image}>
        <StaticImage className="parallaxDiv" {...image} />
      </div>
    </div>

    {/* RIGHT */}
    <BigTextBlock className={styles.right} {...menu} />
  </div>
);

export default RestaurantMenu;
