import React, { useEffect, useRef } from "react";

import * as styles from "./RestaurantContact.module.scss";

import ContactBlock from "../../../components/ContactBlock";
import Button from "../../../components/Button";
import Link from "../../../components/Link";

const RestaurantContact = ({ setSectionSpacing }) => {
  const blockRef = useRef();
  const contactRef = useRef();

  const handleResize = () => {
    let height = contactRef.current?.getBoundingClientRect()?.height || 0;

    blockRef.current.style.height = `${height / 2}px`;

    setSectionSpacing(height / 2);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={blockRef} className={styles.block}>
      <ContactBlock parentRef={contactRef} left={<Left />} right={<Right />} />
    </div>
  );
};

export default RestaurantContact;

const Left = () => (
  <div className={styles.left}>
    <h3 className={styles.title}>
      CONTACT
      <br />
      INFORMATION
    </h3>
    <div className={styles.address}>
      <Link href="">
        Kato Myloi,
        <br />
        Mykonos 84600
      </Link>
    </div>
    <div className={styles.phone}>
      <Link href="tel:0030 2289024676">+30 22890 24676</Link>
    </div>
    <div className={styles.email}>
      <Link href="mailto:info@mykonosbygryparis.com">
        info@mykonosbygryparis.com
      </Link>
    </div>
  </div>
);

const Right = () => (
  <div className={styles.right}>
    <h3 className={styles.title}>
      OPENING
      <br />
      HOURS
    </h3>
    <div className={styles.info}>
      Every day from 16:00 until 00:00
      <br />
      Last order 23:00
    </div>
    <br />
    <Button className={styles.button} href="#contact" target={""}>
      CONTACT US
    </Button>
  </div>
);
