import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../components/BigTextBlock";

import * as styles from "./LocationBlock.module.scss";

import locationSVG from "../../assets/images/icons/location.svg";

const title = "Location | Mykonos by Gryparis";

const locationImage = {
  src: locationSVG,
  alt: "",
  style: { width: "100%", height: "100%" },
};

const info = {
  Header: (props) => (
    <>
      <div {...props} className={`${styles.logo} ${props.className || ""}`}>
        <img {...locationImage} />
      </div>
      <br />
      <br />
      <div className={`${styles.title} fadeInUp`}>
        Touched by the Sea &<br />
        Kissed by the Breeze...
      </div>
    </>
  ),
  text: "No sight can be more evocative of Mykonos than the iconic Windmills. Under the shade of these Cycladic beauties, Mykonos by Gryparis enjoys the most privileged seafront location on the island. Literally kissed by the sea, it has been treating generously its clientele to the most unforgettable summer experiences, with the sea, the endearing view of Little Venice and the most epic Aegean sunsets as a backdrop. An all-time-favourite destination that adds new chapters to its tale every summer. Let’s write yours…",
  color: "white",
};

const location = {
  src: "../../assets/images/location/mykonos_gryparis.jpg",
  alt: "",
  objectFit: "cover",
};

const LocationBlock = () => {
  return (
    <div className={styles.block}>
      {/* Hidden Title */}
      <h2 className={styles.mainTitle}>{title}</h2>

      <div className={styles.container}>
        {/* LEFT */}
        <div className={styles.left}>
          <BigTextBlock className={styles.left} {...info} />
        </div>
        {/* RIGHT */}
        <div className={styles.right}>
          <StaticImage
            className={`${styles.image}  parallaxImage`}
            {...location}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationBlock;
