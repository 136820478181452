import React from "react";

import Link from "../Link";

import * as styles from "./Button.module.scss";

const Button = (props) => {
  return (
    <Link {...props} className={`${styles.button} ${props.className || ""}`}>
      {props.children}
    </Link>
  );
};

export default Button;
