import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";

import * as styles from "./RestaurantIntro.module.scss";

import restaurantSVG from "../../../assets/images/icons/rest_logo_blue.svg";
import bookSVG from "../../../assets/images/icons/bookATable.svg";

const restaurantImage = {
  src: restaurantSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};

const bookImage = {
  src: bookSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};

/* INTRO */
const store = {
  Header: (props) => (
    <>
      <div {...props} className={`${styles.logo} ${props.className || ""}`}>
        <img {...restaurantImage} />
      </div>
      <br />
      <br />
      <div className={`${styles.title} fadeInUp`}>A Legendary Restaurant</div>
    </>
  ),
  text: "Try the flavours of traditional Mediterranean cuisine and the vibrant delights of the sea in Mykonos by Gryparis restaurant. Fresh fish, festive seafood, vibrant salads, traditional recipes, the most refined wine and champagne labels and the aroma of the Aegean Sea… Indulge in the epicurean expression of Mediterranean and Greek cuisine at a place where authentic simplicity blends with luxury and island joie de vivre.",
  Footer: (props) => (
    <a
      href="#contact"
      {...props}
      className={`${styles.cta} ${props.className || ""}`}
    >
      <img {...bookImage} />
    </a>
  ),
};

const image = {
  src: "../../../assets/images/restaurant/intro/dish.png",
  alt: "",
  objectFit: "contain",
  quality: "100",
};

const RestaurantIntro = () => (
  <div className={styles.intro}>
    {/* LEFT */}
    <BigTextBlock className={styles.left} {...store} />

    {/* RIGHT */}
    <div className={styles.right}>
      <div className={styles.image}>
        <StaticImage className="parallaxDiv" {...image} />
      </div>
    </div>
  </div>
);

export default RestaurantIntro;
