import * as React from "react";

import Layout from "../components/Layout";
import HeroBlock from "../blocks/HeroBlock";
import TextBlock from "../blocks/TextBlock";

import RestaurantBlock from "../blocks/RestaurantBlock";
import BarBlock from "../blocks/BarBlock";
import LocationBlock from "../blocks/LocationBlock";
import Map from "../components/Map";
import Footer from "../blocks/Footer";
import Header from "../blocks/Header/Header";

const IndexPage = () => {
  const [sectionSpacing, setSectionSpacing] = React.useState(0);

  return (
    <Layout>
      <Header />
      <main>
        <HeroBlock />
        <TextBlock />
        <RestaurantBlock setSectionSpacing={setSectionSpacing} />
        <BarBlock sectionSpacing={sectionSpacing} />
        <LocationBlock />
        <Map />
        <Footer />
      </main>
    </Layout>
  );
};

export default IndexPage;
