import React from "react";

import ContactBlock from "../../../components/ContactBlock";
import Link from "../../../components/Link";
import Button from "../../../components/Button";

import * as styles from "./BarContact.module.scss";

const BarContact = () => (
  <div className={styles.block}>
    <ContactBlock left={<Left />} right={<Right />} />
  </div>
);

export default BarContact;

const Left = () => (
  <div className={styles.left}>
    <h3 className={styles.title}>
      CONTACT
      <br />
      INFORMATION
    </h3>
    <div className={styles.address}>
      <Link href="">
        Kato Myloi,
        <br />
        Mykonos 84600
      </Link>
    </div>
    <div className={styles.phone}>
      <Link href="tel:0030 2289024676">+30 22890 24676</Link>
    </div>
    <div className={styles.email}>
      <Link href="mailto:info@mykonosbygryparis.com">
        info@mykonosbygryparis.com
      </Link>
    </div>
  </div>
);

const Right = () => (
  <div className={styles.right}>
    <h3 className={styles.title}>
      OPENING
      <br />
      HOURS
    </h3>
    <div className={styles.info}>
      Every day from 16:00 until 00:00
      <br />
      Last order 23:00
    </div>
    <br />
    <Button className={styles.button} href="#contact" target={""}>
      CONTACT US
    </Button>
  </div>
);
