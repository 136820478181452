// extracted by mini-css-extract-plugin
export var block = "HeroBlock-module--block--3a3jX";
export var background = "HeroBlock-module--background--2iOOi";
export var container = "HeroBlock-module--container--pATHK";
export var header = "HeroBlock-module--header--26-PD";
export var contactLink = "HeroBlock-module--contactLink--1hI3B";
export var middle = "HeroBlock-module--middle--14FJF";
export var restaurantImage = "HeroBlock-module--restaurantImage--1ACJx";
export var barImage = "HeroBlock-module--barImage--2yhl3";
export var center = "HeroBlock-module--center--1LHls";
export var heroImage = "HeroBlock-module--heroImage--35uR3";
export var heroBarImage = "HeroBlock-module--heroBarImage--15Rk0";
export var heroRestaurantImage = "HeroBlock-module--heroRestaurantImage--3_f_x";
export var footer = "HeroBlock-module--footer--3DoJ5";
export var discover = "HeroBlock-module--discover--1wB9c";
export var discoverImage = "HeroBlock-module--discoverImage--qUCV0";
export var left = "HeroBlock-module--left--3MARa";
export var right = "HeroBlock-module--right--X1Y_p";
export var logo = "HeroBlock-module--logo--3wrgq";