import React from "react";

import * as styles from "./ContactBlock.module.scss";

const ContactBlock = ({ left, right, parentRef }) => {
  return (
    <div ref={parentRef} className={styles.block}>
      <div className={styles.container}>
        {/* LEFT */}
        <div className={styles.left}>{left}</div>

        {/* SEPERATOR */}
        <div className={styles.seperator} />

        {/* RIGHT */}
        <div className={styles.right}>{right}</div>
      </div>
    </div>
  );
};

export default ContactBlock;
