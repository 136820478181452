import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./HeroBlock.module.scss";

import logoSVG from "../../assets/images/icons/logo.svg";
import restaurantSVG from "../../assets/images/icons/rest_logo_white.svg";
import barSVG from "../../assets/images/icons/bar_logo_white.svg";
import discoverSVG from "../../assets/images/icons/discover.svg";

const background = {
  src: "../../assets/images/hero/bg.jpg",
  alt: "",
};

const contactLink = {
  href: "#contact",
  className: "fadeInUp is--shown",
};

const logo = {
  src: logoSVG,
  alt: "",
  style: { objectFit: "contain" },
};

const heroImage = {
  src: "../../assets/images/hero/hero.jpg",
  alt: "",
};

const heroRestaurantImage = {
  src: "../../assets/images/hero/restaurant_hover.jpg",
  alt: "",
};
const heroBarImage = {
  src: "../../assets/images/hero/bar_hover.jpg",
  alt: "",
};

const restaurantImage = {
  src: restaurantSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};
const barImage = {
  src: barSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};

const discoverImage = {
  src: discoverSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};

const HeroBlock = () => {
  const [isRestaurantHovered, setRestaurantHovered] = useState(false);
  const [isBarHovered, setBarHovered] = useState(false);

  const setRestaurantImageOpacity = () => {
    document.querySelector(".heroBlock-restaurantImage").style.opacity =
      isRestaurantHovered ? 1 : 0;
  };
  const setBarImageOpacity = () => {
    document.querySelector(".heroBlock-barImage").style.opacity = isBarHovered
      ? 1
      : 0;
  };

  useEffect(() => {
    setRestaurantImageOpacity();
  }, [isRestaurantHovered]);
  useEffect(() => {
    setBarImageOpacity();
  }, [isBarHovered]);

  return (
    <div className={styles.block}>
      {/* BACKGROUND */}
      <StaticImage className={styles.background} {...background} />

      <div className={styles.container}>
        {/* HEADER */}
        <div className={styles.header}>
          {/* LOGO */}
          <img className={`${styles.logo} fadeInUp is--shown`} {...logo} />

          {/* CONTACT */}
          <div className={styles.contactLink}>
            <a {...contactLink}>Contact</a>
          </div>
        </div>

        {/* MIDDLE */}
        <div className={styles.middle}>
          {/* LEFT */}
          <div className={styles.left}>
            <a href="#restaurant">
              <img
                className={`${styles.restaurantImage} fadeInUp is--shown`}
                {...restaurantImage}
                onMouseOver={() => {
                  setRestaurantHovered(true);
                }}
                onMouseOut={() => {
                  setRestaurantHovered(false);
                }}
              />
            </a>
          </div>

          {/* CENTER */}
          <div className={styles.center}>
            <StaticImage className={styles.heroImage} {...heroImage} />

            <StaticImage
              className={`${styles.heroRestaurantImage} heroBlock-restaurantImage`}
              {...heroRestaurantImage}
            />

            <StaticImage
              className={`${styles.heroBarImage} heroBlock-barImage`}
              {...heroBarImage}
            />
          </div>

          {/* RIGHT */}
          <div className={styles.right}>
            <a href="#bar">
              <img
                className={`${styles.barImage} fadeInUp is--shown`}
                {...barImage}
                onMouseOver={() => {
                  setBarHovered(true);
                }}
                onMouseOut={() => {
                  setBarHovered(false);
                }}
              />
            </a>
          </div>
        </div>

        {/* FOOTER */}
        <div className={styles.footer}>
          <a href="#discover" className={styles.discover}>
            <img
              className={`${styles.discoverImage} fadeInUp is--shown`}
              {...discoverImage}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroBlock;
