import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";
import SwiperBlock from "../../../components/SwiperBlock";

import * as styles from "./BarAmbience.module.scss";

const ambience = {
  Header: (props) => (
    <div {...props} className={`${styles.title} ${props.className || ""}`}>
      Mykonian Sunsets & Nights
    </div>
  ),
  text: "Discover the epitome of Greek nightlife in a bar that has forged its myth in the thrilling entertainment scene of Mykonos for more than 30 years. It is a common island secret; under the shade of the famous Windmills, with the view of the iconic Little Venice taking centre stage and the sunset saturating the sky every evening, Mykonos by Gryparis Cocktail Bar is the place to experience unprecedented summertime sensations.",
  Footer: (props) => (
    <ul {...props} className={`${styles.links} ${props.className || ""}`}>
      {/* <li>
        <a href="">COCKTAIL MENU</a>
      </li>
      <li>
        <a href="">WINE LIST</a>
      </li> */}
      <li>
        <b>MENU SOON AVAILABLE</b>
      </li>
    </ul>
  ),
};

const swiperImages = [
  <StaticImage
    src={"../../../assets/images/bar/swiper/gryparis_01.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  <StaticImage
    src={"../../../assets/images/bar/swiper/gryparis_02.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  <StaticImage
    src={"../../../assets/images/bar/swiper/gryparis_03.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  <StaticImage
    src={"../../../assets/images/bar/swiper/gryparis_04.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
];

const BarAmbience = () => (
  <div className={styles.ambience}>
    {/* LEFT */}
    <BigTextBlock className={styles.left} {...ambience} />

    {/* RIGHT */}
    <div className={styles.right}>
      <div className={`${styles.swiper} bar-swiper`}>
        <SwiperBlock slides={swiperImages} slidesPerView={1} />
      </div>
    </div>
  </div>
);

export default BarAmbience;
