import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./TextBlock.module.scss";

const background = {
  src: "../../assets/images/textblock/bg.jpg",
  alt: "",
  quality: 100,
};

const text =
  "Your all-time favourite destination, just below the iconic Windmills, has evolved over a course of more than 30 years into a real culinary and entertainment institution in the most arresting corner of Mykonos Town. Experience Mykonos by Gryparis!";

const TextBlock = () => {
  return (
    <div className={styles.block} id="discover">
      {/* BACKGROUND */}
      <StaticImage
        className={`${styles.background} parallaxImage`}
        {...background}
      />

      <div className={styles.container}>
        <div className={`${styles.text} fadeInUp`}>{text}</div>
      </div>
    </div>
  );
};

export default TextBlock;
