import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";

import * as styles from "./SwiperBlock.module.scss";

const SwiperBlock = ({ slides = [], slidesPerView }) => {
  const swiperRef = useRef();

  const slidePrevious = () => {
    swiperRef.current.swiper.slidePrev();
  };
  const slideNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  return (
    <div className={styles.container}>
      <Swiper
        ref={swiperRef}
        spaceBetween={25}
        slidesPerView={slidesPerView || 1}
        loop={true}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={`swiper-slide-${i}`} className={styles.slide}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* PREVIOUS */}
      <div
        className={`${styles.navArrow} ${styles.navArrowPrev}`}
        onClick={slidePrevious}
      >
        <StaticImage src="../../assets/images/arrow.svg" alt="Arrow Left" />
      </div>
      {/* NEXT */}
      <div
        className={`${styles.navArrow} ${styles.navArrowNext}`}
        onClick={slideNext}
      >
        <StaticImage src="../../assets/images/arrow.svg" alt="Arrow Right" />
      </div>
    </div>
  );
};

export default SwiperBlock;
