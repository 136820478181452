import React from "react";

import * as styles from "./BarBlock.module.scss";

import BarIntro from "./BarIntro/";
import BarAmbience from "./BarAmbience/";
import BarContact from "./BarContact/BarContact";

import bg from "../../assets/images/bar/bg.jpg";

const title = "Bar | Mykonos by Gryparis";

const background = {
  src: bg,
  alt: "",
};

const BarBlock = ({ sectionSpacing }) => {
  return (
    <div
      className={styles.block}
      style={{ paddingTop: `${sectionSpacing}px` }}
      id="bar"
    >
      {/* Hidden Title */}
      <h2 className={styles.title}>{title}</h2>

      {/* BACKGOUND */}
      <div className={styles.background}>
        <img {...background} className="parallaxDiv" />
      </div>

      <div className={styles.container}>
        {/* INTRO */}
        <BarIntro />

        {/* AMBIENCE */}
        <BarAmbience />

        {/* CONTACT */}
        <BarContact />
      </div>
    </div>
  );
};

export default BarBlock;
