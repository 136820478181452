import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";

import * as styles from "./BarIntro.module.scss";

import barSVG from "../../../assets/images/icons/bar_logo_blue.svg";
import reservationSVG from "../../../assets/images/icons/make_reservation.svg";

const barImage = {
  src: barSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};
const reservationImage = {
  src: reservationSVG,
  alt: "",
  style: { width: "100%", height: "100%", objectFit: "contain" },
};

const intro = {
  Header: (props) => (
    <>
      <div {...props} className={`${styles.logo} ${props.className || ""}`}>
        <img {...barImage} />
      </div>
      <br />
      <br />
      <div className={`${styles.title} fadeInUp`}>
        An All-Time-Favourite Cocktail Bar
      </div>
    </>
  ),
  text: "Savour the finest drinks and signature cocktails on the island; Feel the magic of a Mykonian night; Immerse in the fun, the vibes, the effusive summer party atmosphere… Discover a sheer Mykonian legend! Mykonos by Gryparis Cocktail Bar has become synonymous with the vibrant nightlife of Mykonos. Beautiful people, bright smiles, summer cocktails and the most uplifting ambience that will have you strutting your funky stuff right by the sea!",
  Footer: (props) => (
    <a
      href="#contact"
      {...props}
      className={`${styles.cta} ${props.className || ""}`}
    >
      <img {...reservationImage} />
    </a>
  ),
};

const bigImage = {
  src: "../../../assets/images/bar/intro/big_image.jpg",
  alt: "",
  objectFit: "cover",
};

const smallImage = {
  src: "../../../assets/images/bar/intro/small_image.png",
  alt: "",
  objectFit: "contain",
};

const BarIntro = () => (
  <div className={styles.intro}>
    {/* LEFT */}
    <div className={`${styles.left} parallaxImage`}>
      <StaticImage className={styles.bigImage} {...bigImage} />

      <StaticImage className={styles.smallImage} {...smallImage} />
    </div>

    {/* RIGHT */}
    <BigTextBlock className={styles.right} {...intro} />
  </div>
);

export default BarIntro;
